export default {
  ROLE_ADMIN: 'Admin',
  ROLE_MANAGER: 'Manager',
  ROLE_ENGINEERING_MANAGER: 'engineering_manager',
  ROLE_HOUSEKEEPING_MANAGER: 'housekeeping_manager',
  ROLE_STEWARDING_MANAGER: 'stewarding_manager',
  ROLE_CLEANING_SUPERVISOR: 'cleaning_supervisor',
  ROLE_ENGINEERING_SUPERVISOR: 'engineering_supervisor',
  ROLE_HOUSEKEEPING_INSPECTOR: 'housekeeping_inspector',
  ROLE_HOUSEKEEPING_SUPERVISOR: 'housekeeping_supervisor',
  ROLE_SECURITY_MANAGER: 'security_manager',
  ROLE_SECURITY_SUPERVISOR: 'security_supervisor',
  ROLE_STEWARDING_SUPERVISOR: 'stewarding_supervisor',
  ROLE_FRONT_DESK_MANAGER: 'front_desk_manager',
  ROLE_FOOD_BEVERAGE_MANAGER: 'food_beverage_manager',
  ROLE_CONCIERGE_MANAGER: 'concierge_manager',
}

export const occupiedTypes = ['OD', 'OC', 'OCI', 'DND', 'RS']

export enum Statuses {
  VD = 'Vacant Dirty',
  VC = 'Vacant Clean',
  VP = 'Vacant Pickup, To Be Inspected',
  VCI = 'Vacant Clean Inspected',
  OD = 'Occupied Dirty',
  OC = 'Occupied Clean',
  OCI = 'Occupied Clean Inspected',
  DND = 'Do Not Disturb',
  RS = 'Refused Service',
  OOO = 'Out Of Order',
}

export enum NoteStatuses {
  not_completed = 'Not Completed',
  completed = 'Completed',
  in_progress = 'In Progress',
}

export enum TurnDownStatusesChange {
  '-' = 'Not Completed',
  T = 'Turn-down finished',
  TI = 'Turn-down inspected',
  DND = 'Do Not Disturb',
  RS = 'Refused Service',
  OOO = 'Out Of Order',
}

export enum TurnDownStatuses {
  '-' = '-',
  T = 'T',
  TI = 'TI',
  DND = 'DND',
  RS = 'RS',
}
export type IRoomStatus =
  | 'OD'
  | 'VD'
  | 'OC'
  | 'VC'
  | 'OCI'
  | 'VCI'
  | 'DND'
  | 'RS'
  | 'T'
  | 'TI'
  | 'OOO'
  | 'OOS'
  | 'VP'
  | '-'

export enum ReservationStatuses {
  'Due Out/Arrival' = 'Due Out/Arrival',
  'Arrival' = 'Arrival',
  'Arrived' = 'Arrived',
  'Due Out' = 'Due Out',
  'Departed' = 'Departed',
  'Stayover' = 'Stayover',
  'Not Reserved' = 'Not Reserved',
  'Departed/Arrival' = 'Departed/Arrival',
  'Arrived/Departed' = 'Arrived/Departed',
}

export function toStatusLonghand(status: string): string {
  switch (status) {
    case 'OCI':
      return 'Occupied Clean, Inspected'
    case 'VCI':
      return 'Vacant Clean, Inspected'
    case 'VD':
      return 'Vacant Dirty'
    case 'OD':
      return 'Occupied Dirty'
    case 'VC':
      return 'Vacant Clean'
    case 'OC':
      return 'Occupied Clean'
    case 'RS':
      return 'Refused Service'
    case 'DND':
      return 'Do Not Disturb'
    case 'VP':
      return 'Vacant Pickup'
    case 'OOO':
      return 'Out Of Order'
    default:
      return ''
  }
}
export const getTurnDown = (status: string): string => {
  switch (status) {
    case '-':
      return 'Turn-Down Not Completed'
    case 'T':
      return 'Turn-Down Completed'
    case 'TI':
      return 'Completed, Inspected'
    case 'DND':
      return 'Do Not Disturb'
    case 'RS':
      return 'Refused Service'
    default:
      return 'Not Completed'
  }
}

export const RoomTypes = [
  'ACSK:Corner Suite ',
  'ADA King',
  'ADA Penthouse',
  'ADA Queen',
  'ADA Queen Room',
  'ADA Standard Queen',
  'ADA Suite',
  'ADA Superior King',
  'ADA Superior Queen',
  'ADQS',
  'AFJK: Fifth Avenue Junior Suite King',
  'AFJT: Fifth Avenue Junior Suite Twin',
  'AFST: Fifth Avenue Suite',
  'AGFK: Grand Suite on 57th',
  'AGSK: Grand Suite',
  'ANSK: Aman Suite',
  'ANSQ: Studio',
  'APFK: Premier Suite on 57th King',
  'APFT: Premier Suite on 57th Twin',
  'APSK: Premier Suite on 57th',
  'ASCK: Premier Sanctuary Suite on 57th King',
  'ASCT: Premier Sanctuary Suite on 57th Twin',
  'AVPH',
  'CCTY',
  'CITA',
  'CITY',
  'CK',
  'CORN',
  'DBDB',
  'DCP',
  'DCV',
  'DDX: Deluxe Double Double',
  'Deluxe room Main House King',
  'DLXK',
  'Double',
  'EKA',
  'EKG',
  'EKNG',
  'Executive King',
  'Garden Wing King Suite',
  'GD',
  'GDA',
  'GK',
  'GKA',
  'GWSK',
  'JNRK',
  'JSCCV',
  'JSCCVA',
  'JSCV',
  'JSRV',
  'JSRVA',
  'JSUITE',
  'Junior Suite Main House King',
  'King',
  'KING',
  'King (patio)',
  'King (w/ snacks)',
  'King Ada (patio)',
  'KNGN',
  'KSTN',
  'KWEN',
  'KWHN',
  'KX: Deluxe King',
  'LXPH',
  'MERK',
  'Merrion Queen',
  'Merrion Suite Main House',
  'MKA',
  'OMGL',
  'OQNN',
  'OWLN',
  'Patio Room',
  'PENT',
  'PENT',
  'PENTH',
  'Penthouse Suite',
  'PENTL',
  'PENTL',
  'PKA',
  'PRCR',
  'PREST',
  'PRMD',
  'PRMK',
  'PRV',
  'PRVA',
  'QNQN',
  'QNQA',
  'Queen',
  'Queen (patio)',
  'Queen Room',
  'Queen Studio Suite',
  'Queen with trundle bed',
  'QUEN',
  'RM1K',
  'S2RVTA',
  'SCCV',
  'SCCVA',
  'SCRVF',
  'SCRVL',
  'SCVB',
  'SDA: Superior Double Accessible',
  'SDD: Superior Double Double',
  'Single',
  'SK',
  'SK: superior king',
  'SKA',
  'SKA: Superior King Accessible',
  'SPEC',
  'Specialty Suite Main House',
  'SRV',
  'SRVT',
  'STA: Loft Suite Accessible',
  'Standard',
  'Standard Full',
  'Standard Queen',
  'STDK',
  'STDQ',
  'STK: Deluxe King Accessible',
  'STKG',
  'SUITE',
  'SUPER',
  'Superking',
  'Superior King',
  'Superior Queen',
  'Superior Room Garden Wing King',
  'Superior Room Garden Wing Twin',
  'SUPK',
  'SUPT',
  'TERR',
  'TQNN',
  'TRIP',
  'TSTN',
  'TWAN',
  'TWEN',
  'TWGN',
  'TWTW',
  'TWYN',
  'VK',
  'WATR',
  'X1GN',
  'X2CN',
  'XDBN',
  'XFTN',
  'XKLN',
  'XOTN',
  'XSTN',
  'XWAN',
] as const

export const getRoomTypes = RoomTypes.map((item) => ({ id: item, name: item }), [])

export const CostLimit = {
  min: 0.5,
  max: 10,
}

export enum ENVIRONMENTS {
  LOCALSHEHAN = 'localShehan',
  LOCALSHAYAN = 'localShayan',
  LOCALKAMRAN = 'localShayan',
  STAGING = 'staging',
  UAT = 'uat',
  PROD = 'production',
}
export const ENVIRONMENT_MODE: string = ENVIRONMENTS.PROD

export const IS_DEV = ENVIRONMENT_MODE === 'local'

export const BASE_URLS: {
  [key: string]: {
    REACT_APP_API_BASE_URL: string
    REACT_APP_WEB_SOCKET_API_URL: string
    REACT_APP_API_TASK_URL: string
  }
} = {
  staging: {
    REACT_APP_API_BASE_URL: 'https://greenparcapi.darkhorsesolutions.co/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://socket.darkhorsesolutions.co/chats/2',
    REACT_APP_API_TASK_URL: 'https://task.darkhorsesolutions.co/',
  },
  uat: {
    REACT_APP_API_BASE_URL: 'https://www.uat.legacymaitre.darkhorsesolutions.co/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://www.uat.socketmaitre.darkhorsesolutions.co/chats/2',
    REACT_APP_API_TASK_URL: 'https://www.uat.apimaitre.darkhorsesolutions.co/',
  },
  production: {
    REACT_APP_API_BASE_URL: 'https://api.maitrehotel.com/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://socket.maitrehotel.com/chats/2',
    REACT_APP_API_TASK_URL: 'https://task.maitrehotel.com/',
  },
  localShehan: {
    REACT_APP_API_BASE_URL: 'http://192.168.100.5:3003/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://greenparcsocket.darkhorsesolutions.co/chats/2',
    REACT_APP_API_TASK_URL: 'http://192.168.100.5:3004/',
  },
  localShayan: {
    REACT_APP_API_BASE_URL: 'http://192.168.100.59:3003/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://greenparcsocket.darkhorsesolutions.co/chats/2',
    REACT_APP_API_TASK_URL: 'http://192.168.100.59:3004/',
  },
  localKamran: {
    REACT_APP_API_BASE_URL: 'http://192.168.0.195:3003/',
    REACT_APP_WEB_SOCKET_API_URL: 'https://greenparcsocket.darkhorsesolutions.co/chats/2',
    REACT_APP_API_TASK_URL: 'http://192.168.0.195:3004/',
  },
}
